import React, { lazy, useState, useEffect, Suspense, useRef } from "react";
import QuizSettings from "./QuizSettings";
import Timer from "./Timer";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import YesNoQuestion from "./YesNoQuestion";
import TrueFalseQuestion from "./TrueFalseQuestion";
import TextQuestion from "./TextQuestion";
import DragDropQuestion from "./DragDropQuestion";
import { FiEdit2 } from "react-icons/fi";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import {
  FiSettings,
  FiX,
  FiCheckCircle,
  FiXCircle,
  FiRefreshCw,
} from "react-icons/fi";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";

const ShowQuiz = ({
  quizData: initialQuizData,
  onUpdate,
  shouldShuffle = true,
  parentEditor = false,
  selectedPathKey = null,
  refreshParent = null,
  disableEdit = false,
}) => {
  // console.log("initialQuizData", initialQuizData);
  const [internalQuizData, setInternalQuizData] = useState(initialQuizData);

  const QuizEditor = lazy(() => import("./QuizEditor"));

  const [parsedData, setParsedData] = useState(null);
  const [solutionLocal, setSolutionLocal] = useState(null);
  const questionRef = useRef(null);
  const [title, setTitle] = useState("");
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const RenderContent = lazy(() => import("../../RenderContent"));
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showAnswer, setShowAnswer] = useState(false);
  const [quizTime, setQuizTime] = useState(300);
  const [showSettings, setShowSettings] = useState(false);
  const [quizEndTime, setQuizEndTime] = useState(0);
  const [quizAttempts, setQuizAttempts] = useState(
    JSON.parse(localStorage.getItem("quizAttempts")) || 0
  );
  const [correctCount, setCorrectCount] = useState(0);
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [tallyPercentage, setTallyPercentage] = useState(0);
  const [timer, setTimer] = useState(0);
  const [isQuizPaused, setIsQuizPaused] = useState(false);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [submittedAnswers, setSubmittedAnswers] = useState([]);
  const [quizEnded, setQuizEnded] = useState(false);
  const [questionSubmitted, setQuestionSubmitted] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [description, setDescription] = useState("Default quiz description");
  const location = useLocation();
  const isNotRoot = location.pathname !== "/";
  const handleQuizDataUpdate = (updatedQuizData) => {
    // Update the parent's internal state
    setInternalQuizData(updatedQuizData);

    // Propagate the update to the grandparent
    if (onUpdate) {
      onUpdate(updatedQuizData);
    }
  };
  // // scroll to title
  // useEffect(() => {
  //   if (questionRef.current) {
  //     questionRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, []); // The empty dependency array ensures this runs only on mount

  useEffect(() => {
    if (quizEnded) {
      // If the quiz has already ended, reset the quiz state and start with the new data
      setInternalQuizData(initialQuizData);
      startQuiz(initialQuizData);
    } else if (!quizEnded && initialQuizData !== internalQuizData) {
      // If the quiz is ongoing and the quiz data has changed, end the current quiz session
      endQuiz();
      // A separate useEffect will handle restarting the quiz after it has ended
    }
  }, [initialQuizData]);

  useEffect(() => {
    if (quizEnded && initialQuizData !== internalQuizData) {
      setInternalQuizData(initialQuizData);
      startQuiz(initialQuizData);
    }
  }, [quizEnded, initialQuizData, internalQuizData]);

  function parseQuizData(data) {
    let parsed;

    if (typeof data === "string") {
      data = removeTrailingCommas(data);
      data = removeCommentsFromJSON(data);
      const fixedData = doubleEscapeBackslashes(data);
      // console.log("fixedData", fixedData);

      try {
        parsed = JSON.parse(fixedData);
      } catch (error) {
        console.error("Error parsing quizData:", error);
        return null; // Return null or an error object if you want to handle the error more gracefully
      }
    } else {
      parsed = data;
    }

    return parsed;
  }

  function shuffleArray(array) {
    // console.log("shuffleArray", shouldShuffle);
    if (shouldShuffle) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
      }
    } else {
      return array;
    }
  }

  const removePrefix = (str) => {
    const pattern = /<(h[1-4]|p|b)>\s*(?:Question\s+)?\d+[\.:]?\s*(.*?)<\/\1>/i;

    return str.replace(pattern, "<$1>$2</$1>");
  };

  const removePrefix1 = (str) => {
    return str;
    // const pattern2 = /(<h[1-4]|<p>)\s*\d+\.\s*/i;
    // return str.replace(pattern2, "$1");
  };

  function doubleEscapeBackslashes(str) {
    if (typeof str === "string") {
      return str.replace(/(?<!\\)\\(?!\\|")/g, "\\\\");
    }
    return str;
  }

  function removeTrailingCommas(s) {
    return s.replace(/,\s*([\]\}])/g, "$1");
  }

  function removeCommentsFromJSON(jsonString) {
    // Remove single-line comments (//...)
    // let result = jsonString.replace(/\/\/.*$/gm, "");

    // Remove multi-line comments (/*...*/)
    // result = result.replace(/\/\*[\s\S]*?\*\//g, "");

    return jsonString;
  }

  useEffect(() => {
    const result = parseQuizData(initialQuizData);
    setParsedData(result);
  }, [initialQuizData]);

  useEffect(() => {
    // console.log("2");
    let localTitle = "";
    let localDescription = "";
    let localQuestions = [];

    if (
      parsedData &&
      typeof parsedData === "object" &&
      Object.keys(parsedData).length > 1
    ) {
      if (parsedData.quiz) {
        ({
          title: localTitle,
          description: localDescription,
          questions: localQuestions,
        } = parsedData.quiz);
      } else {
        ({
          title: localTitle,
          description: localDescription,
          questions: localQuestions,
        } = parsedData);
      }

      // Update the title
      setTitle(localTitle);
      setDescription(localDescription || "A unique quiz experience"); // Set a default or fallback description

      // console.log("localQuestions", localQuestions);

      // Update the questions after removing prefixes
      if (Array.isArray(localQuestions)) {
        const modifiedQuestions = localQuestions.map((question) => {
          return {
            ...question,
            text: removePrefix1(removePrefix(question.text)),
          };
        });
        shuffleArray(modifiedQuestions); // Shuffle the questions
        setShuffledQuestions(modifiedQuestions);
        // Set the quiz time based on the number of questions
        setQuizTime(modifiedQuestions.length * 30);
        // console.log(modifiedQuestions);
      }
    }
    // console.log(localTitle);
    // console.log(shuffledQuestions);
  }, [parsedData, initialQuizData]);

  // console.log(questions);

  useEffect(() => {
    if (
      shuffledQuestions &&
      shuffledQuestions.length > 1 &&
      currentQuestion + 1 === shuffledQuestions.length
    ) {
      // Quiz has reached the last question
      setShowAnswer(false);
    }
  }, [currentQuestion, shuffledQuestions]);

  useEffect(() => {
    if (showAnswer) {
      calculateScore();
    }
  }, [showAnswer]);

  const refreshQuestions = () => {
    startQuiz();
  };

  useEffect(() => {
    // console.log("1");
    if (shuffledQuestions) {
      const percentage = (correctCount / shuffledQuestions.length) * 100;
      setTallyPercentage(percentage);
    }
  }, [correctCount, incorrectCount, shuffledQuestions]);

  const startQuiz = () => {
    // console.log("startQuiz");
    setAnswers([]);
    setCurrentQuestion(0);
    setShowAnswer(false);
    setQuizAttempts((prevAttempts) => prevAttempts + 1);
    setQuizEndTime(Date.now() + 24 * 60 * 60 * 1000); // Set quiz end time 24 hours from now
    localStorage.setItem("quizAttempts", JSON.stringify(quizAttempts + 1));
    localStorage.setItem("quizEndTime", JSON.stringify(quizEndTime));
    // Reset additional state variables
    setCorrectCount(0);
    setIncorrectCount(0);
    setTallyPercentage(0);
    setTimer(0);
    setIsQuizPaused(false);
    setIsTimerStarted(false);
    setSelectedAnswers([]);
    setSubmittedAnswers([]);
    setQuizEnded(false);
    setQuestionSubmitted(false);
    // Set the quiz time based on the number of questions
    setQuizTime(shuffledQuestions.length * 30);
    setShowSettings(false); // reset showSettings to its initial state
    shuffleArray(shuffledQuestions);
  };

  // Check if parsedData is an empty object and return null if so
  // Check if parsedData is not an object or is an empty object
  if (
    (parsedData && typeof parsedData !== "object") ||
    (parsedData && Object.keys(parsedData).length === 0)
  ) {
    return (
      <div>
        Please enter Valid JSON...Use a JSON validator to write and validate
        JSON
      </div>
    );
  }

  const endQuiz = () => {
    setQuizEnded(true);
  };

  const calculateScore = () => {
    // console.log("inside calculateScore");
    let updatedCorrectCount = correctCount;
    let updatedIncorrectCount = incorrectCount;

    // console.log("currentQuestion", currentQuestion);
    if (currentQuestion === 0) {
      setCorrectCount(0);
      updatedCorrectCount = 0;
      updatedIncorrectCount = 0;
      setIncorrectCount(0);
    }
    // console.log("submittedAnswers", submittedAnswers);
    const questionIndex = currentQuestion;
    const userAnswer = selectedAnswers[questionIndex];
    const question = shuffledQuestions[questionIndex];
    const solution = shuffledQuestions[questionIndex].solution;

    // console.log("userAnswer", userAnswer);

    // console.log("question", question, "userAnswer", userAnswer);
    // console.log("questionIndex", questionIndex);
    // console.log(
    //   "isCorrectAnswer(questionIndex)",
    //   isCorrectAnswer(questionIndex)
    // );
    if (question.type === "multiple_choice") {
      if (isCorrectAnswer(questionIndex)) {
        setCorrectCount((prevCorrectCount) => {
          updatedCorrectCount = prevCorrectCount + 1;
          return updatedCorrectCount;
        });
      } else {
        setIncorrectCount((prevIncorrectCount) => {
          updatedIncorrectCount = prevIncorrectCount + 1;
          return updatedIncorrectCount;
        });
      }
    } else if (question.type === "yes_no" || question.type === "true_false") {
      if (userAnswer === question.answer) {
        setCorrectCount((prevCorrectCount) => {
          updatedCorrectCount = prevCorrectCount + 1;
          return updatedCorrectCount;
        });
      } else {
        setIncorrectCount((prevIncorrectCount) => {
          updatedIncorrectCount = prevIncorrectCount + 1;
          return updatedIncorrectCount;
        });
      }
    } else if (question.type === "text") {
      if (userAnswer.toLowerCase() === question.answer.toLowerCase()) {
        setCorrectCount((prevCorrectCount) => {
          updatedCorrectCount = prevCorrectCount + 1;
          return updatedCorrectCount;
        });
      } else {
        setIncorrectCount((prevIncorrectCount) => {
          updatedIncorrectCount = prevIncorrectCount + 1;
          return updatedIncorrectCount;
        });
      }
    } else if (question.type === "drag_drop") {
      // Perform appropriate checks based on the answer verification logic
    }

    // Debug logs to see the values
    // console.log(updatedCorrectCount, updatedIncorrectCount);

    // const percentage = (updatedCorrectCount / shuffledQuestions.length) * 100;

    // // More debug logs to understand the calculations
    // console.log("updatedCorrectCount", updatedCorrectCount);
    // console.log("updatedIncorrectCount", updatedIncorrectCount);

    // console.log("percentage", percentage);

    // setTallyPercentage(percentage);

    // return Math.round(percentage);
  };

  const isCorrectAnswer = () => {
    // console.log("test");
    const question = shuffledQuestions[currentQuestion];

    let selectedAnswer = selectedAnswers[currentQuestion];
    const correctAnswer = question.answer;

    if (question.type == "true_false" || question.type == "yes_no") {
      // console.log("correctAnswer", correctAnswer);
      // console.log("selectedAnswer", selectedAnswer);
      return selectedAnswer === correctAnswer;
    }

    // Check if either selectedAnswer or correctAnswer is undefined or null
    if (!selectedAnswer || !correctAnswer) {
      return false;
    }
    // console.log("selectedAnswer isCorrectAnswer", selectedAnswer);
    // console.log("question.type", question.type);

    if (selectedAnswer.length === 0) {
      // console.log("boom ");
      return false;
    }

    if (question.type == "text") {
      // console.log(selectedAnswer, correctAnswer);

      const sanitizeInput = (input) => {
        if (input.includes("=")) {
          return input.replace(/\s+/g, "").toLowerCase();
        }
        return input.toLowerCase();
      };

      const selectedWords = sanitizeInput(selectedAnswer)
        .toLowerCase()
        .split(/\s+/);
      const correctWords = sanitizeInput(correctAnswer)
        .toLowerCase()
        .split(/\s+/);

      // Returns true if at least one word in selectedWords matches a word in correctWords
      return selectedWords.some((word) => correctWords.includes(word));
    }

    selectedAnswer = selectedAnswers[currentQuestion].flat();

    if (question.multiple_answers) {
      if (
        Array.isArray(selectedAnswer) &&
        Array.isArray(correctAnswer) &&
        selectedAnswer.length === correctAnswer.length
      ) {
        return (
          selectedAnswer.every((answer) => correctAnswer.includes(answer)) &&
          correctAnswer.every((answer) => selectedAnswer.includes(answer))
        );
      } else {
        return false;
      }
    } else {
      return selectedAnswer.every((answer) => correctAnswer.includes(answer));
    }
  };

  const handleAnswerSelection = (selectedAnswersForCurrentQuestion) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      return [
        ...prevSelectedAnswers.slice(0, currentQuestion),
        selectedAnswersForCurrentQuestion,
        ...prevSelectedAnswers.slice(currentQuestion + 1),
      ];
    });

    // If it's a single-answer question, perform additional operations

    if (!isTimerStarted) {
      setIsTimerStarted(true); // Start the timer if it hasn't been started yet
    }
    if (
      shuffledQuestions &&
      shuffledQuestions[currentQuestion] &&
      !shuffledQuestions[currentQuestion].multiple_answers
    ) {
      setShowAnswer(true); // Show answer when an option is selected
    }
  };

  const handleSubmit = () => {
    // Update the score if the answer is correct
    const isCorrect = isCorrectAnswer();
    const score = isCorrect ? 1 : 0;

    setAnswers((prevAnswers) => [...prevAnswers, selectedAnswers]);
    setSubmittedAnswers((prevSubmittedAnswers) => [
      ...prevSubmittedAnswers,
      currentQuestion,
    ]);
    setShowAnswer(true);
    setIsQuizPaused(true); // Pause the quiz
    setQuestionSubmitted(true); // Set the question as submitted

    // Update the score
    setCorrectCount((prevCorrectCount) => prevCorrectCount + score);

    // Start the timer if it hasn't been started yet
    if (!isTimerStarted) {
      setIsTimerStarted(true);
    }
  };

  const handleQuizTimeChange = (e) => {
    const newQuizTime = parseInt(e.target.value, 10);
    setQuizTime(newQuizTime);
  };

  const handleSettingsToggle = () => {
    setShowSettings((prevShowSettings) => !prevShowSettings);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  const handlePreviousQuestion = () => {
    setShowAnswer(false); // Reset showAnswer state
    if (currentQuestion > 0) {
      setCurrentQuestion((prevQuestion) => prevQuestion - 1);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestion < shuffledQuestions.length - 1) {
      setShowAnswer(false); // Reset showAnswer state
      setCurrentQuestion((prevQuestion) => prevQuestion + 1);
      // Scroll to the question title after updating the currentQuestion
      if (questionRef.current) {
        questionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const processSelectedAnswers = (selectedAnswers, options) => {
    if (!Array.isArray(selectedAnswers)) {
      return getOptionText(selectedAnswers, options); // or however you want to handle this case
    }
    return selectedAnswers.map((selectedAnswer) =>
      getOptionText(selectedAnswer, options)
    );
  };

  const getProgressBarWidth = () => {
    const progress = ((currentQuestion + 1) / shuffledQuestions.length) * 100;
    return `${progress}%`;
  };

  const AnsweredQuestion = ({
    question,
    selectedAnswers,
    correctAnswer,
    showAnswer,
    solutionLocal,
  }) => {
    const isCorrect = isCorrectAnswer();

    const RenderSolution = () => (
      <div className="solution mt-6 text-left flex flex-col items-center">
        <h1 className="text-3xl text-yellow-500">Solution</h1>
        {Array.isArray(solutionLocal) ? (
          solutionLocal.map((step, index) => (
            <div key={index} className="w-full">
              <RenderContent content={step} />
            </div>
          ))
        ) : (
          <RenderContent content={solutionLocal} />
        )}
      </div>
    );

    const getAnswerText = (answer) => {
      switch (question.type) {
        case "yes_no":
          return answer ? "Yes" : "No";
        case "true_false":
          return answer ? "True" : "False";
        default:
          return String(answer);
      }
    };

    const ResultIcon = ({ isCorrect }) =>
      isCorrect ? (
        <FiCheckCircle className="text-green-500 text-3xl animate-pulse" />
      ) : (
        <FiXCircle className="text-red-500 text-3xl animate-pulse" />
      );

    const CorrectnessText = ({ isCorrect }) => (
      <p
        className={`text-gray-500 font-bold text-3xl ${
          isCorrect ? "" : "mt-4"
        }`}
      >
        {isCorrect ? "Correct" : "Wrong!"}
      </p>
    );

    const AnswerBlock = ({ title, answer, isCorrect }) => (
      <div className="flex items-center space-x-2 mt-4 mb-4">
        <p className="text-gray-500 font-bold">{title}:</p>
        <div
          className={`font-bold p-2 rounded ${
            isCorrect ? "bg-green-500" : "bg-red-500"
          }`}
        >
          <Suspense fallback={<div>Loading...</div>}>
            {Array.isArray(answer) ? (
              answer.map((ans, idx) => (
                <div key={idx}>
                  <RenderContent content={getAnswerText(ans)} />
                </div>
              ))
            ) : (
              <RenderContent content={getAnswerText(answer)} />
            )}
          </Suspense>
        </div>
      </div>
    );

    if (!showAnswer) return null;

    let answersContent = (
      <>
        <ResultIcon isCorrect={isCorrect} />
        <CorrectnessText isCorrect={isCorrect} />
        {!isCorrect && (
          <AnswerBlock
            title="You Selected"
            answer={selectedAnswers}
            isCorrect={isCorrect}
          />
        )}
        <AnswerBlock
          title="Correct Answer"
          answer={correctAnswer}
          isCorrect={true}
        />
      </>
    );

    // For other question types (not "yes_no", "true_false", or "text")
    if (["yes_no", "true_false", "text"].indexOf(question.type) === -1) {
      answersContent = (
        <>
          <ResultIcon isCorrect={isCorrect} />
          <CorrectnessText isCorrect={isCorrect} />
          {!isCorrect && (
            <AnswerBlock
              title="You Selected"
              answer={processSelectedAnswers(selectedAnswers, question.options)}
              isCorrect={isCorrect}
            />
          )}
          <AnswerBlock
            title="Correct Answer"
            answer={processSelectedAnswers(correctAnswer, question.options)}
            isCorrect={true}
          />
        </>
      );
    }

    return (
      <div className="mb-6">
        <div className="flex flex-col items-center justify-between">
          {answersContent}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          <RenderSolution />
        </Suspense>
      </div>
    );
  };

  const MemoizedAnsweredQuestion = React.memo(AnsweredQuestion);

  const getOptionText = (value, options) => {
    // console.log("value", value, "options", options);

    if (Array.isArray(value)) {
      // value is an array, find all matching options
      const optionTexts = value.map((val) => {
        const option = options.find((option) => option.value == val);
        return option ? option.text : "";
      });
      // join all option texts together with comma separation
      return optionTexts.join(" ");
    } else {
      // value is a string, find the matching option
      const option = options.find((option) => option.value == value);
      // console.log("option", option);
      return option ? option.text : "";
    }
  };

  const QuestionComponent = () => {
    // Ensure that there is a valid question at the current index.
    useEffect(() => {
      if (shuffledQuestions && shuffledQuestions[currentQuestion]) {
        const question = shuffledQuestions[currentQuestion];
        const solution = question.solution;
        setSolutionLocal(solution);
      }
    }, [shuffledQuestions, currentQuestion]);
    if (
      !shuffledQuestions ||
      shuffledQuestions.length <= currentQuestion ||
      !shuffledQuestions[currentQuestion]
    ) {
      return null; // or a placeholder component or message if you prefer
    }

    // console.log("shuffledQuestions", shuffledQuestions);

    let question = shuffledQuestions[currentQuestion];
    question.text = removePrefix(question.text);
    // console.log("question.text", question.text);
    const solution = question.solution; // Now we're sure that question is not undefined

    const displayQuestionNumber = (
      <div className="mb-4">
        Question {currentQuestion + 1} of {shuffledQuestions.length}
      </div>
    );

    if (question.type === "multiple_choice") {
      if (question && question.multiple_answers) {
        return (
          <>
            {" "}
            {displayQuestionNumber}
            <MultipleChoiceQuestion
              question={question}
              selectedAnswers={selectedAnswers}
              selectedAnswer={selectedAnswers[currentQuestion]}
              correctAnswer={question.answer} // Add this line
              handleAnswerSelection={handleAnswerSelection}
              currentQuestion={currentQuestion} // add this line
              showAnswer={showAnswer}
              solution={solution}
            />
          </>
        );
      } else {
        return (
          <>
            {" "}
            {displayQuestionNumber}
            <MultipleChoiceQuestion
              question={question}
              correctAnswer={question.answer} // Add this line
              selectedAnswers={selectedAnswers}
              selectedAnswer={selectedAnswers[currentQuestion]}
              handleAnswerSelection={handleAnswerSelection}
              currentQuestion={currentQuestion} // add this line
              showAnswer={showAnswer}
              solution={solution}
            />
          </>
        );
      }
    } else if (question.type === "yes_no") {
      return (
        <>
          {" "}
          {displayQuestionNumber}
          <YesNoQuestion
            question={question}
            selectedAnswers={selectedAnswers}
            selectedAnswer={selectedAnswers[currentQuestion]}
            correctAnswer={question.answer} // Add this line
            handleAnswerSelection={handleAnswerSelection}
            currentQuestion={currentQuestion} // add this line
            showAnswer={showAnswer}
            solution={solution}
          />
        </>
      );
    } else if (question.type === "true_false") {
      return (
        <>
          {" "}
          {displayQuestionNumber}
          <TrueFalseQuestion
            question={question}
            selectedAnswers={selectedAnswers}
            selectedAnswer={selectedAnswers[currentQuestion]}
            correctAnswer={question.answer} // Add this line
            handleAnswerSelection={handleAnswerSelection}
            currentQuestion={currentQuestion} // add this line
            showAnswer={showAnswer}
          />
        </>
      );
    } else if (question.type === "text") {
      return (
        <>
          {" "}
          {displayQuestionNumber}
          <TextQuestion
            question={question}
            selectedAnswers={selectedAnswers}
            selectedAnswer={selectedAnswers[currentQuestion]}
            correctAnswer={question.answer} // Add this line
            handleAnswerSelection={handleAnswerSelection}
            currentQuestion={currentQuestion} // add this line
            showAnswer={showAnswer}
            solution={solution}
          />
        </>
      );
    } else if (question.type === "drag_drop") {
      return (
        <>
          {" "}
          {displayQuestionNumber}
          <DragDropQuestion
            question={question}
            selectedAnswers={selectedAnswers}
            selectedAnswer={selectedAnswers[currentQuestion]}
            correctAnswer={question.answer} // Add this line
            handleAnswerSelection={handleAnswerSelection}
            currentQuestion={currentQuestion} // add this line
            showAnswer={showAnswer}
          />
        </>
      );
    }

    return null;
  };

  let data = initialQuizData;

  if (typeof initialQuizData === "string") {
    try {
      data = JSON.parse(doubleEscapeBackslashes(initialQuizData));
    } catch (error) {
      console.error("Error parsing quizData string:", error);
      return <div>Invalid data format...</div>;
    }
  }

  let questionsLocal;
  let titleLocal;
  if (data && data.questions) {
    questionsLocal = data.questions;
    titleLocal = data.title;
  } else if (data && data.quiz && data.quiz.questions) {
    questionsLocal = data.quiz.questions;
    titleLocal = data.quiz.title;
  }

  if (!questionsLocal) {
    // console.log(typeof data);
    // console.log(data);
    return <div>Nothing here yet...</div>;
  }

  const toggleEditor = () => {
    setShowEditor(!showEditor);
  };

  return (
    <>
      {isNotRoot && (
        <Helmet>
          <title>{title}</title>
          <meta
            name="description"
            content={description || "Default description"}
          />
        </Helmet>
      )}
      <div className="container w-full h-full mx-auto p-1">
        <div className="flex justify-between items-center mb-2">
          <h1
            ref={questionRef}
            className="text-2xl text-center text-gray-600 font-bold"
          ></h1>
          {showEditor ? (
            // Exit Edit Mode button
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={() => {
                if (showEditor) {
                  const userConfirmed = window.confirm(
                    "Do you wish to continue without saving? Press 'OK' to exit without saving or 'Cancel' to remain in edit mode."
                  );
                  if (userConfirmed) {
                    setShowEditor(false); // set showEditor to false to exit edit mode only if user confirms
                  }
                } else {
                  setShowEditor(false);
                }
              }}
            >
              Exit Edit Mode
            </button>
          ) : (
            <>
              {showSettings ? (
                <button
                  className="bg-gray-200 px-4 py-2 rounded-md flex items-center space-x-2"
                  onClick={handleCloseSettings}
                >
                  <FiX size={24} />
                </button>
              ) : (
                <div className="flex items-center ">
                  {/* Edit button */}
                  {!disableEdit && false && (
                    <button
                      className="bg-gray-400 px-4 py-2 rounded-md mr-2"
                      onClick={toggleEditor} // Make sure the toggleEditor function is defined somewhere in your component
                      title="Edit Quiz"
                    >
                      <FiEdit2 size={24} />{" "}
                      {/* Assuming FiEdit2 is imported and available */}
                    </button>
                  )}

                  {/* Refresh button - Disabled when showEditor is true */}
                  <button
                    className={`bg-gray-400 px-4 py-2 rounded-md mr-2 ${
                      showEditor ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={refreshQuestions}
                    title="Refresh Questions"
                    disabled={showEditor} // Disabled attribute based on showEditor state
                  >
                    <FiRefreshCw size={24} />
                  </button>

                  {/* Settings button - Disabled when showEditor is true */}
                  <button
                    className={`bg-gray-400 px-4 py-2 rounded-md ${
                      showEditor ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleSettingsToggle}
                    disabled={showEditor} // Disabled attribute based on showEditor state
                  >
                    <FiSettings size={24} />
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {showEditor && (
          <div className="editor-modal">
            <React.Suspense fallback={<div>Loading...</div>}>
              <QuizEditor
                quiz={questionsLocal}
                title={titleLocal}
                onUpdate={(updatedQuizData) => {
                  handleQuizDataUpdate(updatedQuizData);
                  setShowEditor(false); // Exit Edit Mode
                }}
                onExit={() => {
                  setShowEditor(false);
                  if (refreshParent) {
                    // console.log("refreshParent");
                    refreshParent();
                  }
                }}
                parentEditor={parentEditor}
                selectedPathKey={selectedPathKey}
              />
            </React.Suspense>
          </div>
        )}

        {showSettings && (
          <div className="max-w-md mx-auto bg-white rounded-md p-4 m-2 mb-4 shadow mt-4">
            <h2 className="text-lg font-bold mb-4">Quiz Settings</h2>
            <QuizSettings
              quizTime={quizTime}
              handleQuizTimeChange={handleQuizTimeChange}
            />
          </div>
        )}

        {/* Quiz Questions */}
        {!showEditor && !quizEnded && (
          <div className="max-w-md mx-auto bg-white rounded-md p-4 shadow">
            {showAnswer && (
              <>
                {shuffledQuestions &&
                shuffledQuestions[currentQuestion] &&
                shuffledQuestions[currentQuestion].multiple_answers ? (
                  // Render the AnsweredQuestion component for multiple answers
                  <MemoizedAnsweredQuestion
                    question={shuffledQuestions[currentQuestion]}
                    selectedAnswers={selectedAnswers[currentQuestion]}
                    correctAnswer={shuffledQuestions[currentQuestion].answer}
                    showAnswer={true}
                    solutionLocal={solutionLocal}
                  />
                ) : (
                  // Render the AnsweredQuestion component for single answer
                  <MemoizedAnsweredQuestion
                    question={shuffledQuestions[currentQuestion]}
                    selectedAnswers={selectedAnswers[currentQuestion]}
                    correctAnswer={shuffledQuestions[currentQuestion].answer}
                    showAnswer={true}
                    solutionLocal={solutionLocal}
                  />
                )}
              </>
            )}
            <div className={showAnswer ? "bg-gray-400 p-3 m-3 rounded" : ""}>
              <QuestionComponent />
            </div>

            {shuffledQuestions &&
              shuffledQuestions[currentQuestion] &&
              shuffledQuestions[currentQuestion].multiple_answers &&
              !showAnswer && (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50 mt-4"
                  onClick={handleSubmit}
                  disabled={
                    !selectedAnswers[currentQuestion] ||
                    selectedAnswers[currentQuestion].length === 0
                  }
                >
                  Submit
                </button>
              )}

            {showAnswer && (
              <>
                <button
                  className={`bg-blue-500 text-white px-4 py-2 rounded-md ${
                    currentQuestion === shuffledQuestions.length - 1
                      ? "hidden"
                      : ""
                  }`}
                  onClick={handleNextQuestion}
                >
                  Next
                  <GrFormNext className="inline-block ml-2" />
                </button>
              </>
            )}

            {currentQuestion === shuffledQuestions.length - 1 && showAnswer && (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50 mt-4"
                onClick={endQuiz}
              >
                Show Results
                <GrFormNext className="inline-block ml-2" />
              </button>
            )}
          </div>
        )}

        {/* Quiz Progress */}

        {isTimerStarted && (
          <div className="max-w-md mx-auto bg-white rounded-md p-4 shadow mt-4">
            <div className="h-2 bg-gray-300 rounded-full">
              <div
                className="h-2 bg-blue-500 rounded-full"
                style={{ width: getProgressBarWidth() }}
              ></div>
            </div>
          </div>
        )}

        {/* Quiz Timer */}
        {!quizEnded && isTimerStarted && (
          <Timer
            initialTime={0}
            isTimerStarted={isTimerStarted}
            quizTime={quizTime}
            endQuiz={endQuiz}
          />
        )}

        {/* Quiz Results */}
        {quizEnded && (
          <div className="max-w-md mx-auto bg-white rounded-md p-4 shadow">
            <h2 className="text-lg font-bold mb-4 text-center">Result</h2>
            <p className="text-xl text-center mb-4">
              Total questions: {shuffledQuestions.length}
            </p>
            <p className="text-center">
              <span className="font-bold text-green-500">Correct answers:</span>{" "}
              {correctCount}
            </p>
            <p className="text-center p-2">
              <span className="font-bold text-red-500">Incorrect answers:</span>{" "}
              {incorrectCount}
            </p>
            <p className="text-center p-2">
              <span className="font-bold">Passing score:</span> 80%
            </p>
            <p className="text-center p-2">
              <span className="font-bold">Your score:</span>{" "}
              {Math.round(tallyPercentage)}%
            </p>
            <p className="text-center mt-4 p-2">
              {tallyPercentage >= 80 ? (
                <span className="text-green-500 text-lg font-bold">
                  Congratulations! You passed the quiz!
                </span>
              ) : (
                <span className="text-red-500 text-lg font-bold">
                  Sorry, you didn't pass the quiz.
                </span>
              )}
            </p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded-md disabled:opacity-50 mt-6 mx-auto block hover:bg-blue-600 focus:bg-blue-600"
              onClick={startQuiz}
            >
              Start Again
            </button>
          </div>
        )}

        {/* Quiz Navigation */}

        {/* Settings */}
      </div>
    </>
  );
};

export default ShowQuiz;
