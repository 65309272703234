import React, { useState, useEffect, useRef } from "react";
import PreviewGuide from "../PreviewGuide";
import getJSONfromPublic from "../getJSONfromPublic"; // Adjust this path to where your getJSONfromPublic function is located
import { RainbowText, RainbowButton, RainbowButton1 } from "./Rainbow";
import { useNavigate } from "react-router-dom";
import { MdOutlineAutoAwesome } from "react-icons/md";
import useOnScreen from "../useOnScreen"; // Adjust the import path to where your useOnScreen hook is located

// Updated words and descriptions to match the new marketing message
const actionWords = ["Revolutionize", "Elevate"];
const actionDescriptions = [
  "Effortlessly Create How-To Video Masterpiece with Generative AI",
  "Collaborate and Share Videos with Ease in Landsape and Portrait Mode",
  "10X Your Digital Adoption Video Productivity",
];

const actionDescriptions1 = [
  "Revolutionize Digital Adoption GIF Creation with Generative AI",
  "Elevate your GIF creation process with the magic of Generative AI.",
  "10X Your Digital Adoption GIF Productivity",
];

const actionDescriptions2 = [
  "Revolutionize Digital Adoption Google/Microsoft Slide Creation with the Generative AI",
  "Elevate your Google Slide and Powerpoint Digital Adoption presentations with the power of Generative AI.",
  "Accelerate your Digital Adoption Slide creation process, making it 10X faster and more innovative.",
];
// Utilize Tailwind CSS for styling and animation
const tailwindBlink = "animate-ping";

function FeaturedGuide() {
  // Create refs for your lazy-loaded components
  const FBRef = useRef();
  const llmRef = useRef();
  const guideRef = useRef();
  const howtoRef = useRef();
  const chatGPTRef = useRef();
  const portraitRef = useRef();
  const docRef = useRef();

  // Use the custom hook to check if the elements are in the viewport
  const isFBVisible = useOnScreen(FBRef);
  const isLlmVisible = useOnScreen(llmRef);
  const isGuideVisible = useOnScreen(guideRef);
  const isHowtoVisible = useOnScreen(howtoRef);
  const isChatGPTVisible = useOnScreen(chatGPTRef);
  const isPortraitVisible = useOnScreen(portraitRef);
  const isDocVisible = useOnScreen(docRef);

  const [persistVisibility, setPersistVisibility] = useState({
    FB: false,
    llm: false,
    guide: false,
    howto: false,
    chatGPT: false,
    portrait: false,
    doc: false,
  });

  // Updated visibility logic with persisting behavior
  useEffect(() => {
    const updateVisibility = (isVisible, key) => {
      if (isVisible && !persistVisibility[key]) {
        setPersistVisibility((prev) => ({ ...prev, [key]: true }));
      }
    };

    updateVisibility(isFBVisible, "FB");
    updateVisibility(isLlmVisible, "llm");
    updateVisibility(isGuideVisible, "guide");
    updateVisibility(isHowtoVisible, "howto");
    updateVisibility(isChatGPTVisible, "chatGPT");
    updateVisibility(isPortraitVisible, "portrait");
    updateVisibility(isDocVisible, "doc");
  }, [
    isFBVisible,
    isLlmVisible,
    isGuideVisible,
    isHowtoVisible,
    isChatGPTVisible,
    isPortraitVisible,
    isDocVisible,
  ]);

  const navigate = useNavigate();
  const [actionIndex, setActionIndex] = useState(0);
  const [guideData, setGuideData] = useState(null); // State to hold fetched guide data
  const [howtoData, setHowToData] = useState(null); // State to hold fetched guide data
  const [chatGPT, setChatGPT] = useState(null); // State to hold fetched guide data
  const [portraitData, setPortraitData] = useState(null); // State to hold fetched guide data
  const [docData, setDocData] = useState(null); // State to hold fetched guide data
  const [llmData, setLlmData] = useState(null); // State to hold fetched guide data
  const [FBData, setFBData] = useState(null); // State to hold fetched guide data
  const [contentTypeIndex, setContentTypeIndex] = useState(0); // State for cycling content types
  const contentTypes = ["Videos", "GIF", "Doc", "Slide"];
  const currentContentType = contentTypes[contentTypeIndex]; // Current content type (Videos, GIF, Doc, Slide)

  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentTypeIndex((prevIndex) => (prevIndex + 1) % contentTypes.length);
    }, 2000); // Change the content type every 2 seconds
    return () => clearInterval(contentTypeInterval);
  }, []);

  // git wVdZ4zMt1PYD6mmNflk6ZApiZEe2
  // wag YcHmlVjlgaMTJldkZ3I2cyuaEj82

  useEffect(() => {
    const fetchGuideData = async () => {
      // Define your UID and session IDs for each type of guide
      const guideSessionId = "recording_1710801416482_510852"; // Replace with your actual session ID
      const howtoSessionId = "recording_1709971042833_7218fbbdad965"; // Replace with your actual session ID
      const chatGPTSessionId = "recording_1710269452137_7b3f2a88975b4"; // Replace with your actual session ID
      const portraitVideoSessionId = "recording_1711386443960_556407"; // Replace with your actual session ID
      const docId = "recording_1711867514055_2e6af5342e498"; // Replace with your actual session ID
      const LLMId = "recording_1715028107857_69e3286ae4754"; // Replace with your actual session ID
      const FBId = "recording_1714859084370_867432"; // Replace with your actual session ID

      try {
        const FBData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          FBId
        );

        const LLMData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          LLMId
        );
        const guideData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          guideSessionId
        );
        const howtoData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          howtoSessionId
        );
        const chatGPTData = await getJSONfromPublic(
          "wVdZ4zMt1PYD6mmNflk6ZApiZEe2",
          chatGPTSessionId
        );

        const portraitData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          portraitVideoSessionId
        );

        const docData = await getJSONfromPublic(
          "YcHmlVjlgaMTJldkZ3I2cyuaEj82",
          docId
        );
        if (FBData)
          setFBData({
            ...FBData,
            guideTitle: "Featured AI Guide: " + FBData.guideTitle,
          });
        if (LLMData)
          setLlmData({
            ...LLMData,
            guideTitle: "Featured Generative Guide: " + LLMData.guideTitle,
          });

        if (guideData)
          setGuideData({
            ...guideData,
            guideTitle: "Featured Guide: " + guideData.guideTitle,
          });
        if (howtoData)
          setHowToData({
            ...howtoData,
            guideTitle: "Featured Video: " + howtoData.guideTitle,
          });
        if (chatGPTData)
          setChatGPT({
            ...chatGPTData,
            guideTitle: "Featured GIF: " + chatGPTData.guideTitle,
          });
        if (portraitData)
          setPortraitData({
            ...portraitData,
            guideTitle: "Featured Portrait Video: " + portraitData.guideTitle,
          });
        if (docData)
          setDocData({
            ...docData,
            guideTitle: "Featured Guide:  " + docData.guideTitle,
          });
        // console.log(portraitData);
      } catch (error) {
        console.error("There was a problem fetching guide data:", error);
      }
    };

    fetchGuideData();

    const wordChangeInterval = setInterval(() => {
      setActionIndex((prevIndex) => (prevIndex + 1) % actionWords.length);
    }, 4000); // Change the word every 4 seconds
    return () => clearInterval(wordChangeInterval);
  }, []);

  const currentDescription1 = actionDescriptions1[actionIndex];
  const currentDescription2 = actionDescriptions2[actionIndex];

  const handleButtonClick = () => {
    window.open(
      "https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1",
      "_blank"
    );
  };

  return (
    <>
      <section className="bg-gradient-to-r from-blue-50 to-gray-100 py-12 px-6">
        <div className="flex justify-center mb-4">
          <h2 className="text-3xl font-bold mb-8 mt-8 text-gray-700">
            Create Videos - With Generative AI
          </h2>
        </div>

        <div className="flex justify-center">
          <div className="max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <div className="flex justify-between items-center mb-4">
              <div className="text-lg font-semibold text-gray-800">
                Install AI Extension
              </div>
              <MdOutlineAutoAwesome className="text-blue-500 text-2xl" />
            </div>
            <p className="text-gray-600 mb-4">
              Create Videos, GIFs, Docs, and Slides easily with AI-powered
              tools.
            </p>
            <RainbowButton1
              onClick={handleButtonClick}
              className="w-full text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-transform transform hover:scale-105"
            >
              Install AI Extension
            </RainbowButton1>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturedGuide;
