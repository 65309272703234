import React, { useState, useEffect } from "react";
import { RainbowButtonHeader, RainbowButton1 } from "./Rainbow";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineAutoAwesome,
  MdOutlineVideoLibrary,
  MdGif,
  MdInsertDriveFile,
  MdViewCarousel,
} from "react-icons/md";

const actionWords = ["Revolutionize", "Elevate"];
const actionDescriptions = [
  "Turn any process into How To Guides",
  "Assist Customers and Employees",
  "Build training docs and videos",
  "Better - Faster - Superior",
];

const tailwindBlink = "animate-ping";

function Hero() {
  const [actionIndex, setActionIndex] = useState(0);
  const navigate = useNavigate();
  const [contentTypeIndex, setContentTypeIndex] = useState(0);

  useEffect(() => {
    const wordChangeInterval = setInterval(() => {
      setActionIndex(
        (prevIndex) => (prevIndex + 1) % actionDescriptions.length
      );
    }, 3000);
    return () => clearInterval(wordChangeInterval);
  }, []);

  const currentAction = actionWords[actionIndex];
  const currentDescription = actionDescriptions[actionIndex];
  const contentTypes = ["Videos", "GIF", "Doc", "Slide"];
  const currentContentType = contentTypes[contentTypeIndex];
  const contentTypeIcons = {
    Videos: { icon: MdOutlineVideoLibrary, color: "text-white" },
    GIF: { icon: MdGif, color: "text-white" },
    Doc: { icon: MdInsertDriveFile, color: "text-white" },
    Slide: { icon: MdViewCarousel, color: "text-white" },
  };

  const ContentTypeIcon = contentTypeIcons[currentContentType].icon;
  const iconColor = contentTypeIcons[currentContentType].color;

  useEffect(() => {
    const contentTypeInterval = setInterval(() => {
      setContentTypeIndex((prevIndex) => (prevIndex + 1) % contentTypes.length);
    }, 3000);
    return () => clearInterval(contentTypeInterval);
  }, []);

  return (
    <>
      {/* New section for Mechanical Engineering Card */}
      <section className="bg-gray-50 text-gray-800 py-12 px-6 md:px-12">
        <div className="container mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 mt-8 text-gray-400">
            Engineering - Made Easy
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center">
            {/* Mechanical Engineering Card */}
            <div
              className="bg-gray-800 rounded-lg shadow-xl p-6 cursor-pointer hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
              onClick={() =>
                navigate("/college/engineering/mechanical-engineering")
              }
            >
              <div
                className="h-48 bg-cover bg-center rounded-lg"
                style={{
                  backgroundImage: `url('/tutorials/engineering/mechanical-engineering/images/preface-and-introduction-to-mechanical-engineering-abc101-portrait.jpeg')`,
                }}
              ></div>
              <h3 className="text-lg font-bold mb-2 mt-4 text-[#00FFFF]">
                Mechanical Engineering - Made Easy
              </h3>
              <p className="text-md text-white">
                101 Videos and interactive quizzes
              </p>
            </div>
            {/* Placeholder cards for future content */}
            <div
              className="bg-gray-800 rounded-lg shadow-xl p-6 cursor-pointer hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
              onClick={() =>
                navigate("/college/engineering/electrical-engineering")
              }
            >
              <div
                className="h-48 bg-cover bg-center rounded-lg"
                style={{
                  backgroundImage: `url('/tutorials/engineering/electrical-engineering/images/preface-and-introduction-to-electrical-engineering-abc101-portrait.jpeg')`,
                }}
              ></div>
              <h3 className="text-lg font-bold mb-2 mt-4 text-[#00FFFF]">
                Electrical Engineering - Made Easy
              </h3>
              <p className="text-md text-white">
                101 Videos and interactive quizzes
              </p>
            </div>
            <div
              className="bg-gray-800 rounded-lg shadow-xl p-6 cursor-pointer hover:shadow-2xl transform hover:scale-105 transition-transform duration-300"
              onClick={() => navigate("/college/engineering/civil-engineering")}
            >
              <div
                className="h-48 bg-cover bg-center rounded-lg"
                style={{
                  backgroundImage: `url('/tutorials/engineering/civil-engineering/images/preface-and-introduction-to-civil-engineering-abc101-portrait.jpeg')`,
                }}
              ></div>
              <h3 className="text-lg font-bold mb-2 mt-4 text-[#00FFFF]">
                Civil Engineering - Made Easy
              </h3>
              <p className="text-md text-white">
                101 Videos and interactive quizzes
              </p>
            </div>
          </div>
          <p className="mt-5">More engineering titles coming soon...</p>
        </div>
      </section>
    </>
  );
}

export default Hero;
