import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
  useRef,
  useContext,
} from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./login";
import { FaSpinner } from "react-icons/fa";
import { auth, db } from "./firebase";
import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet";
import CatchAll from "./CatchAll";
import { AuthContext } from "./AuthProvider";
import {
  useParams,
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomeLayout from "./HomeLayout";
import GoRedirect from "./GoRedirect"; // Import the GoRedirect component
import "./App.css";
import AnalyticsLogger from "./AnalyticsLogger";

// Utility function to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const [showLogin, setShowLogin] = useState(false);
  const [loginNotification, setLoginNotification] = useState(false);
  const [notificationShown, setNotificationShown] = useState(false);
  const [logoutNotification, setLogoutNotification] = useState(false);

  const GoForm = React.lazy(() => import("./components/GoForm"));
  const GoList = React.lazy(() => import("./components/GoList"));
  const Go = React.lazy(() => import("./Go"));
  const OKR = React.lazy(() => import("./OKR"));
  const VideoCreate = React.lazy(() => import("./VideoCreate"));
  const Guide = React.lazy(() => import("./Guide"));
  const MyGuides = React.lazy(() => import("./MyGuide"));
  const Course = React.lazy(() => import("./Course"));
  const My = React.lazy(() => import("./My"));
  const TutorialCreate = React.lazy(() => import("./TutorialCreate"));
  const LectureCreate = React.lazy(() => import("./LectureCreate"));
  const Quiz = React.lazy(() => import("./QuizCreate"));
  const Family = React.lazy(() => import("./Family"));
  const ShowPublicQuiz = React.lazy(() =>
    import("./components/quiz/showPublicQuiz")
  );
  const MyQuiz = React.lazy(() => import("./components/quiz/myQuiz"));
  const DreamLayout = React.lazy(() => import("./DreamLayout"));
  const SubscribePage = React.lazy(() => import("./SubscribePage"));
  const PrivacyLayout = React.lazy(() => import("./PrivacyLayout"));
  const ShowVideo = React.lazy(() => import("./components/ShowGuide"));
  const renderCount = useRef(0);
  const authContextValue = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const isVideoEmbed =
    window.location.pathname.includes("/video") &&
    new URLSearchParams(window.location.search).get("embed") === "true";

  // State and useEffects declarations...

  useEffect(() => {
    //   console.log("window.location.pathname", window.location.pathname);
    const isGuidePage =
      window.location.pathname.includes("/myguide") ||
      (window.location.pathname.includes("/guide") &&
        new URLSearchParams(window.location.search).has("id"));

    // Delay execution using setTimeout
    const delay = 2000; // Delay in milliseconds (1000ms = 1s)

    const timer = setTimeout(() => {
      if (isGuidePage && !user) {
        //   console.log("isGuidePage", isGuidePage);
        setShowLogin(true);
      }

      if (user) {
        setShowLogin(false);
      }
    }, delay);

    // Cleanup function to clear the timeout if the component unmounts
    // before the timeout is completed or if the effect reruns
    return () => clearTimeout(timer);
  }, [user]); // Dependency array

  // setTimeout(() => {
  //   const essentialElement = document.getElementById("navbar");
  //   if (!essentialElement) {
  //     window.location.reload();
  //   }
  // }, 1000);

  function capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  // Define a route that checks if the quizId is a number with four or more digits

  function DynamicMetaTags() {
    // console.log("boom");
    const location = useLocation();
    const [title, setTitle] = useState("Kimavi | Made Easy"); // Default title
    const [description, setDescription] = useState(
      "Learning Made Easy with Generative AI. Creating Learning content Made Easy with Generative AI. Try Today!."
    ); // Default description

    useEffect(() => {
      const segments = location.pathname
        .split("/")
        .filter((segment) => segment);

      let newTitle;
      let newDescription;

      // Check if the current path is under 'family'
      if (segments[0] === "family" && segments.length >= 1) {
        const treeName = segments[1]; // Assuming treeName is the second segment
        newTitle = `Family Tree of ${capitalize(treeName)}`;
        newDescription = `Discover the Family Tree of ${capitalize(
          treeName
        )}. Explore your heritage and uncover the stories of your ancestors and the paths they traveled. Connect the dots of your family's past to discover the who, what, and when of your lineage. Gain insights into potential health predispositions - understand what your DNA might say about risks for conditions like diabetes or cancer.`;
      } else {
        // Default title and description for other paths
        newTitle = "Kimavi | Made Easy";

        let baseDescription =
          "Learning Made Easy with Generative AI. Creating Learning content Made Easy with Generative AI. Try Today!.";

        if (segments.length) {
          newTitle +=
            " - " +
            segments
              .map((segment) => capitalize(segment.replace(/-/g, " ")))
              .join(" - ");
        }

        newDescription = `${baseDescription} Dive deeper into ${newTitle}.`;

        const empowerSegmentDescription = (segment) => {
          return `Empower your ${capitalize(
            segment
          )} learning journey with Generative AI and LLMs.`;
        };

        const segmentsWithDescriptions = {
          school: empowerSegmentDescription("School"),
          college: empowerSegmentDescription("College"),
          languages: empowerSegmentDescription("Languages"),
          references:
            "Get insights from our references and empower your learning journey with Generative AI and LLMs.",
        };

        for (const segment in segmentsWithDescriptions) {
          if (segments.includes(segment)) {
            newDescription += " " + segmentsWithDescriptions[segment];
          }
        }
      }
      setTitle(newTitle);
      setDescription(newDescription);
    }, [location]);
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
    );
  }

  function capitalize(text) {
    if (!text) return "";
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  // rest of the App component

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
    // console.log(`Rendered ${renderCount.current} times`);
  });

  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const showLogoutNotification = () => {
    // setLogoutNotification(true);
    // setTimeout(() => {
    //   setLogoutNotification(false);
    // }, 3000);
  };

  const handleSignOut = () => {
    showLogoutNotification();
    signOut(auth).catch((error) => {
      console.error(error);
    });
  };

  const closeLogin = () => {
    setShowLogin(false);
  };

  const showLoginNotification = () => {
    // if (!notificationShown && !hasSignedIn) {
    //   // Check if not already shown and has not signed in
    //   setLoginNotification(true);
    //   setNotificationShown(true);
    //   setHasSignedIn(true); // Update the state to indicate that sign-in has occurred
    //   setTimeout(() => {
    //     setLoginNotification(false);
    //   }, 3000);
    // }
  };

  if (!authContextValue) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <FaSpinner icon="spinner" className="animate-spin" size={50} />
      </div>
    );
  }

  // Inside your App component, before the return statement
  function QuizRoute() {
    let { quizId } = useParams();
    if (quizId && quizId.match(/^\d{4,}$/)) {
      return <ShowPublicQuiz />;
    } else {
      // If quizId doesn't match, redirect to the homepage or another component
      return <CatchAll />;
    }
  }

  return (
    <div>
      <Router>
        <DynamicMetaTags />
        <AnalyticsLogger /> {/* <-- Add this line */}
        {!isVideoEmbed && (
          <Navbar
            onLoginClick={handleLoginClick}
            handleSignOut={handleSignOut}
          />
        )}
        {showLogin && <Login closeLogin={() => setShowLogin(false)} />}
        {logoutNotification && (
          <div className="fixed top-0 right-0 bg-green-500 text-white px-4 py-2 rounded">
            Successfully logged out
          </div>
        )}
        {loginNotification && (
          <div className="fixed top-0 right-0 bg-green-500 text-white px-4 py-2 rounded">
            Successfully signed in
          </div>
        )}
        <div className="flex w-full h-full">
          <div className="flex w-full h-full">
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route exact path="/" element={<HomeLayout />} />
                <Route path="/:quizId" element={<QuizRoute />} /> */}
                {/* Updated line

                {/* <Route exact path="/dream" element={<DreamLayout />} />
                <Route path="/go" element={<Go />} />
                <Route path="/okr" element={<OKR />} />
                 */}
                <Route path="/guide" element={<Guide />} />
                <Route path="/create" element={<Guide />} />
                <Route path="/myguide" element={<MyGuides />} />
                <Route path="/video" element={<ShowVideo />} />
                <Route path="/course" element={<Course />} />
                {/* <Route path="/video" element={<ShowVideo />} />
                
                 */}
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/login" element={<Login />} />
                <Route path="/quiz/:quizId" element={<ShowPublicQuiz />} />
                <Route path="/tutorial" element={<TutorialCreate />} />
                <Route path="/lecture" element={<LectureCreate />} />
                <Route path="/go/:name" element={<GoRedirect />} />
                <Route path="/subscribe" element={<SubscribePage />} />
                <Route path="/my" element={<My />} />
                <Route path="/myquiz" element={<MyQuiz />} />
                <Route path="/family/:familyName" element={<Family />} />
                <Route path="/family" element={<Family />} />
                <Route path="/privacy" element={<PrivacyLayout />} />
                <Route path="/security" element={<PrivacyLayout />} />
                <Route path="/cookie" element={<PrivacyLayout />} />
                <Route
                  path="/privacy-security-cookie"
                  element={<PrivacyLayout />}
                />
                {/* Add the GoRedirect route */}
                {/* Your other routes */}
                {/* Add the catch-all route */}
                <Route path="*" element={<CatchAll />} />
              </Routes>
            </Suspense>
          </div>
        </div>
        {!isVideoEmbed && <Footer />}
        {isVideoEmbed && (
          <div className="text-left p-2 text-xs">
            Powered by{" "}
            <a
              href="https://www.kimavi.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kimavi
            </a>
          </div>
        )}
      </Router>
    </div>
  );
}

export default App;
