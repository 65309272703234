import React, { useEffect, useState, useCallback, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiSettings, FiChevronDown, FiChevronRight } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FiMenu, FiX, FiSearch } from "react-icons/fi";
import { BsLightningFill } from "react-icons/bs";
import { FaGlobe, FaUniversity } from "react-icons/fa";
import { featuredGuide } from "../constants/featured";

function Sidebar({ onGuideSelect, onTabChange }) {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openItems, setOpenItems] = useState({}); // to keep track of which items are open
  const [tabIndex, setTabIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const searchInputRef = useRef(null);
  const hideme = false;

  // recording_1710617314996_9e00f868b670d

  // http://localhost:3000/video?id=recording_1710617314996_9e00f868b670d&source=server&uid=YcHmlVjlgaMTJldkZ3I2cyuaEj82
  // http://localhost:3000/video?id=recording_1710658769883_927321&source=server&uid=wVdZ4zMt1PYD6mmNflk6ZApiZEe2

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  };

  const slugify = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  // State to hold the randomized guides
  const [randomGuides, setRandomGuides] = useState([]);

  // Shuffle the guides when the component mounts
  useEffect(() => {
    setRandomGuides(shuffleArray([...featuredGuide]));
  }, []);

  const prepareHierarchyData = useCallback(
    (data, parentPath = "", depth = 0) => {
      return data.map((item) => {
        const path = `${parentPath}/${slugify(item.title)}`;
        const newItem = {
          ...item,
          path,
          depth,
        };
        if (item.children && item.children.length > 0) {
          newItem.children = prepareHierarchyData(
            item.children,
            path,
            depth + 1
          );
        }
        return newItem;
      });
    },
    []
  );

  const initialHierarchyData = [];

  const createHierarchyData = [];

  const my = [
    {
      title: "My Guides",
      path: "/myguide",
    },
    {
      title: "Create New  Guide",
      path: "/create",
    },
    {
      title: "Create New Quiz",
      path: "/myquiz",
    },
    {
      title: "Create New Course",
      path: "/course",
    },
  ];

  const [hierarchyData, setHierarchyData] = useState(() =>
    prepareHierarchyData(initialHierarchyData)
  );

  useEffect(() => {
    const checkPathInData = (data) => {
      // console.log("location.pathname", location.pathname);
      // console.log("data", data);
      for (const item of data) {
        if (location.pathname.includes(item.path)) {
          return true;
        }
        if (item.children && checkPathInData(item.children)) {
          return true;
        }
      }
      return false;
    };

    if (checkPathInData(hierarchyData)) {
      // console.log("hierarchyData", hierarchyData);
      // console.log("2");
      setTabIndex(0);
    } else if (checkPathInData(my)) {
      // console.log("my", my);
      // console.log("0");
      setTabIndex(0);
    }
  }, [hierarchyData, location.pathname]);

  const fetchData = useCallback(async () => {
    const res = await fetch("/data.json");
    const data = await res.json();
    const preparedData = prepareHierarchyData(data);
    setHierarchyData(preparedData);
    // Set fetched data in localStorage with a Unix timestamp
    localStorage.setItem("hierarchyData", JSON.stringify(preparedData));
    localStorage.setItem("unixtimestamp", Date.now().toString());
  }, [prepareHierarchyData]);

  useEffect(() => {
    const storedData = localStorage.getItem("hierarchyData");
    const storedTimestamp = localStorage.getItem("unixtimestamp");
    if (storedData && storedTimestamp) {
      setHierarchyData(JSON.parse(storedData));
    } else {
      fetchData();
    }
  }, [fetchData]);

  useEffect(() => {
    const checkAndUpdateData = async () => {
      const storedTimestamp = localStorage.getItem("unixtimestamp");
      const serverTimeRes = await fetch("/data_time.json");
      const { unixtimestamp: serverTime, forceUpdate } =
        await serverTimeRes.json();

      // Check if the forceUpdate flag is true or if server time is greater than stored timestamp
      if (forceUpdate || !storedTimestamp || serverTime > storedTimestamp) {
        fetchData();
      } else {
        const storedData = localStorage.getItem("hierarchyData");
        if (storedData) {
          setHierarchyData(JSON.parse(storedData));
        } else {
          fetchData();
        }
      }
    };
    checkAndUpdateData();
  }, [fetchData]);

  const transformForComparison = (string) =>
    string.toLowerCase().replace(/ /g, "-");

  useEffect(() => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment !== "");
    const expandedItems = {};

    // Traverse through the path segments to build the hierarchy
    let currentPath = "";
    pathSegments.forEach((segment, index) => {
      currentPath += `/${segment}`;
      const item = hierarchyData.find((data) =>
        transformForComparison(data.path).startsWith(
          transformForComparison(currentPath)
        )
      );
      if (item) {
        expandedItems[item.title] = true;

        // Open the children of the last segment in the path
        if (index === pathSegments.length - 1 && item.children) {
          item.children.forEach((child) => {
            expandedItems[child.title] = true;
          });
        }
      }
    });

    setOpenItems(expandedItems);
  }, [location.pathname, hierarchyData]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleOpen = useCallback((item, open) => {
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [item.title]: open !== prevOpenItems[item.title] ? open : false,
    }));
  }, []);

  const renderHierarchy = (data, depth = 0) => {
    return (
      <ul>
        {data.map((item, index) => {
          const isOpen = openItems[item.title];
          const isItemSelected = location.pathname === item.path;
          return (
            <li
              key={index}
              className={`bg-gray-800 p-4 rounded-lg m-2 text-white`} // Base background and text color
            >
              <div className="flex items-center">
                <div
                  className={`${
                    item.children && item.children.length > 0
                      ? "cursor-pointer"
                      : ""
                  }`}
                  onClick={() => toggleOpen(item, !isOpen)}
                >
                  {item.children && item.children.length > 0 ? (
                    isOpen ? (
                      <FiChevronDown className="text-white mr-2" />
                    ) : (
                      <FiChevronRight className="text-white mr-2" />
                    )
                  ) : (
                    <span className="inline-block w-6"></span> // Spacer when no children
                  )}
                </div>
                <Link
                  to={item.path}
                  className={`px-4 py-2 rounded-md ${
                    isItemSelected
                      ? "bg-gray-600 text-white text-center"
                      : "text-cyan-300 hover:bg-gray-900 text-center"
                  }`}
                  style={{ fontSize: `${1.0 - depth * 0}rem` }} // Font size decreases as depth increases
                >
                  {item.title}
                </Link>
              </div>
              {isOpen && item.children && (
                <ul className="pl-0">
                  {" "}
                  {/* Removing padding to align children with parent */}
                  {renderHierarchy(item.children, depth)}
                </ul>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const filterHierarchyData = useCallback(() => {
    if (searchTerm.length < 3) {
      setFilteredData([]);
      return;
    }

    const filterData = (data) => {
      return data
        .flatMap((item) => {
          if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
            return item;
          }
          if (item.children) {
            return filterData(item.children);
          }
          return [];
        })
        .filter(Boolean);
    };

    const results = [
      ...filterData(initialHierarchyData),
      ...filterData(hierarchyData),
    ];
    setFilteredData(results);
  }, [searchTerm, hierarchyData]);

  useEffect(() => {
    filterHierarchyData();
  }, [searchTerm, filterHierarchyData]);

  const toSentenceCase = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleGuideSelect = (guide) => {
    onGuideSelect(guide);
  };

  const groupByFirstPathElement = (data) => {
    return data.reduce((acc, item) => {
      if (item.path) {
        const firstPathElement = item.path.split("/")[1];
        if (!acc[firstPathElement]) {
          acc[firstPathElement] = [];
        }
        acc[firstPathElement].push(item);
      }
      return acc;
    }, {});
  };
  const renderSearchResults = (data) => {
    const groupedData = groupByFirstPathElement(data);

    return (
      <div className="space-y-2">
        {" "}
        {/* Use div here instead of ul */}
        {Object.entries(groupedData).map(([key, items]) => (
          <div key={key} className="space-y-2">
            <span className="text-gray-700 font-bold">
              {toSentenceCase(key)}
            </span>
            <ul className="list-disc pl-5 space-y-2">
              {items
                .filter((item) => item.path && item.path.length > 2)
                .map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <Link
                      to={item.path}
                      className="px-4 py-2 rounded-md text-gray-800 hover:bg-gray-300"
                    >
                      {toSentenceCase(item.title)}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="flex ">
        <button
          onClick={toggleSidebar}
          className="bg-gray-400 text-white p-2 rounded-md shadow-md md:hidden p-2 m-2"
        >
          {sidebarOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>
      </div>
      <div
        className={`sm:flex ${
          sidebarOpen ? "block sm:hidden" : "hidden"
        } bg-gray-300 dark:bg-gray-400`}
      >
        <div className="">
          {hideme && (
            <div className="p-1 flex flex-col w-72 bg-gray-200">
              <div className="flex items-center relative">
                <input
                  type="text"
                  ref={searchInputRef}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search..."
                  className="border bg-gray-200 p-2 flex-grow pl-10" // added padding-left to avoid overlap with the icon
                />
                {hideme && searchTerm ? (
                  <FiX
                    onClick={() => setSearchTerm("")}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    size={24}
                  />
                ) : (
                  <FiSearch
                    onClick={() => searchInputRef.current.focus()}
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                    size={24}
                  />
                )}
              </div>
              {hideme && searchTerm && searchTerm.length >= 3 && (
                <div className="mt-2 p-4 bg-gray-200 border rounded-md shadow">
                  <h3 className="text-gray-600 mb-2">Search Results:</h3>
                  {filteredData.length > 0 ? (
                    renderSearchResults(filteredData)
                  ) : (
                    <div className="text-gray-500">Nothing here yet...</div>
                  )}
                </div>
              )}
            </div>
          )}

          <nav
            className={`${
              sidebarOpen || window.innerWidth > 640
                ? "flex flex-col w-72 h-full bg-gray-200 shadow-md py-2 px-2 space-y-4 transition-all duration-300"
                : "hidden md:flex md:flex-col md:w-72 md:h-full md:bg-gray-200 md:shadow-md md:py-4 md:px-6 md:space-y-4 transition-all duration-300"
            }`}
          >
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => {
                setTabIndex(index); // Update the local state to reflect the new tab index
                if (onTabChange) {
                  // Check if the onTabChange function is provided
                  onTabChange(index); // Call the onTabChange function with the new index
                }
              }}
            >
              <TabList>
                {/* <Tab>
                  <div className="flex items-center">
                    <FaGlobe className="mr-1 text-gray-500" /> Explorer
                  </div>
                </Tab> */}
                {/* <Tab>Create</Tab> */}

                <Tab>
                  <div className="flex items-center">Learn</div>
                </Tab>

                <Tab>
                  <div className="flex items-center">Create</div>
                </Tab>
              </TabList>

              <TabPanel>
                <div className="space-y-2">
                  {renderHierarchy(hierarchyData)}
                </div>
              </TabPanel>
              <TabPanel>
                <div className="space-y-2">{renderHierarchy(my)}</div>
              </TabPanel>
              {/* 
              <TabPanel>
                <div className="space-y-4">
                  {randomGuides.map((guide, index) => (
                    <div
                      key={index}
                      className="max-w-md mx-auto bg-gray-800 rounded-xl shadow-md overflow-hidden md:max-w-2xl"
                    >
                      <div className="md:flex">
                        <div
                          className="p-8 cursor-pointer"
                          onClick={() => handleGuideSelect(guide)} // Add the onClick event here
                        >
                          <a
                            href="#"
                            onClick={(e) => e.preventDefault()} // Prevent the default anchor action
                            className="block mt-1 text-sm leading-tight font-medium text-cyan-300 hover:underline"
                          >
                            {guide.guideTitle}
                          </a>
                          <p className="mt-2 text-sm text-gray-400">
                            #{guide.tags.join(" #")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel> */}

              {/* <TabPanel>
                <div className="space-y-2">
                  {renderHierarchy(createHierarchyData)}
                </div>
              </TabPanel> */}

              <div className="mt-auto">
                {/* {renderDreamButton()} */}
                <Link
                  to="/subscribe"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-300 rounded-md"
                >
                  <BsLightningFill className="inline-block mr-2" />
                  Subscribe
                </Link>
              </div>
            </Tabs>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
