import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../UserContext"; // import UserContext
import { AuthContext } from "../AuthProvider";
import { Link } from "react-router-dom";
import { FaCaretDown, FaUser } from "react-icons/fa"; // Import the FaCaretDown icon from react-icons
import { BsLightningFill } from "react-icons/bs";
import { RainbowButton } from "./Rainbow"; // Adjust the import path as necessary
import { MdOutlineAutoAwesome } from "react-icons/md";

function Navbar({ onLoginClick, handleSignOut }) {
  const [courseDropdownOpen, setCourseDropdownOpen] = useState(false);
  const [appsDropdownOpen, setAppsDropdownOpen] = useState(false);
  const [learnDropdownOpen, setLearnDropdownOpen] = useState(false);
  const { user, isPaid, idToken } = useContext(AuthContext);
  const appsDropdownRef = useRef(null); // Create a ref for the apps dropdown
  const learnDropdownRef = useRef(null); // Create a ref for the learn dropdown
  const CourseDropdownRef = useRef(null); // Create a ref for the Course dropdown
  const userDropdownRef = useRef(null); // Create a ref for the user dropdown

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  // State for force updating cache
  const [forceUpdate, setForceUpdate] = useState(false);
  const [serverDate, setServerDate] = useState(null);
  const [localCacheDate, setLocalCacheDate] = useState(new Date());

  // Mock function to simulate fetching server date from API
  const fetchServerDate = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const date = new Date();
        resolve({ date });
      }, 1000);
    });
  };

  useEffect(() => {
    // Fetch server date when component mounts
    const getServerDate = async () => {
      const response = await fetchServerDate();
      setServerDate(new Date(response.date));
    };
    getServerDate();
  }, []);

  useEffect(() => {
    // Update local cache if server date is higher or if forceUpdate is true
    if (forceUpdate || (serverDate && serverDate > localCacheDate)) {
      console.log("Updating local cache");
      setLocalCacheDate(serverDate || new Date());
      setForceUpdate(false); // Reset forceUpdate after updating
    }
  }, [serverDate, forceUpdate]);

  const toggleCourseDropdown = () => setCourseDropdownOpen(!courseDropdownOpen);
  const toggleUserDropdown = () => setUserDropdownOpen(!userDropdownOpen);
  const toggleAppsDropdown = () => setAppsDropdownOpen(!appsDropdownOpen);
  const toggleLearnDropdown = () => setLearnDropdownOpen(!learnDropdownOpen);

  const CourseLinks = [
    { name: "College", path: "/college" },
    { name: "Subscribe", path: "/subscribe" },
  ];

  const appsLinks = [
    { name: "My Guides", path: "/myguide" },
    { name: "My Quizzes", path: "/myquiz" },
    { name: "Create New Guide", path: "/create" },
    { name: "Create New Course", path: "/course" },
    { name: "Subscribe", path: "/subscribe" },
  ];

  // Event listener to handle clicks outside the dropdowns
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        appsDropdownRef.current &&
        !appsDropdownRef.current.contains(event.target)
      ) {
        setAppsDropdownOpen(false);
      }
      if (
        learnDropdownRef.current &&
        !learnDropdownRef.current.contains(event.target)
      ) {
        setLearnDropdownOpen(false);
      }
    };

    if (appsDropdownOpen || learnDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [appsDropdownOpen, learnDropdownOpen]);

  // Event listener to handle clicks outside the Course dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        CourseDropdownRef.current &&
        !CourseDropdownRef.current.contains(event.target)
      ) {
        setCourseDropdownOpen(false);
      }
    };
    if (courseDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [courseDropdownOpen]);

  // Event listener to handle clicks outside the User dropdown
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setUserDropdownOpen(false);
      }
    };
    if (userDropdownOpen) {
      document.addEventListener("click", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [userDropdownOpen]);

  return (
    <nav id="navbar" className="bg-gray-100 dark:bg-gray-800 py-3 items-center">
      <div className="container px-3 md:px-3 flex items-center justify-between">
        <div className="flex flex-col items-center md:flex-row">
          <RainbowButton
            onClick={() => {
              /* Navigation logic if necessary */
            }}
          >
            <Link to="/" className="flex items-center">
              <MdOutlineAutoAwesome className="mr-2 text-xl md:text-2xl" />
              <span className="text-3xl md:text-5xl font-semibold">Kimavi</span>
            </Link>
          </RainbowButton>
        </div>

        <div className="flex items-center space-x-2 md:space-x-8">
          {/* Learn Dropdown */}
          <div
            ref={learnDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <a
              href="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
            >
              Ext
            </a>
          </div>

          {/* Course Dropdown */}
          <div
            ref={CourseDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
              onClick={toggleCourseDropdown}
            >
              Learn
            </span>
            {courseDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {CourseLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={toggleCourseDropdown}
                      className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Apps Dropdown */}
          <div
            ref={appsDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className="bg-gray-400 hover:bg-blue-700 text-white py-1 px-2 rounded inline-flex items-center"
              onClick={toggleAppsDropdown}
            >
              Create
            </span>
            {appsDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                {appsLinks.map((app, index) => (
                  <li key={index}>
                    <Link
                      to={app.path}
                      onClick={toggleAppsDropdown}
                      className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      {app.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* User Dropdown */}
          <div
            ref={userDropdownRef}
            className="relative inline-flex items-center text-black cursor-pointer text-gray-800 dark:text-gray-200"
          >
            <span
              className={`px-2 py-1 rounded hover:bg-blue-300 ${
                user ? "text-blue-700" : "text-gray-400"
              }`}
              onClick={toggleUserDropdown}
            >
              <FaUser size={24} />
            </span>
            {userDropdownOpen && (
              <ul className="absolute z-10 w-32 bg-black text-white rounded shadow-lg top-full mt-1">
                <li>
                  <Link
                    to="/myguide"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    My Guides
                  </Link>
                </li>
                <li>
                  <Link
                    to="/myquiz"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    My Quizzes
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://chromewebstore.google.com/detail/kimavi-create-how-to-vide/kcleenakjlelbmchbkfpgoldgglhdpne?hl=en&authuser=0&pli=1"
                    onClick={toggleUserDropdown}
                    className="block px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    Install Extension
                  </Link>
                </li>
                <li>
                  <button
                    onClick={() => setForceUpdate(true)}
                    className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                  >
                    Force Update Cache
                  </button>
                </li>
                {user ? (
                  <li>
                    <button
                      onClick={() => {
                        handleSignOut();
                        toggleUserDropdown();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      Logout
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      onClick={() => {
                        onLoginClick();
                        toggleUserDropdown();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-blue-500 hover:text-white"
                    >
                      Login
                    </button>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
