// HomeLayout.js
import React, { useState, useContext, useEffect } from "react"; // import useContext
import { AuthContext } from "./AuthProvider";
import Hero from "./components/Hero";
import FeaturedGuide from "./components/FeaturedGuide";
import Discover from "./components/Discover";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import Partners from "./components/Partners";
import Footer from "./components/Footer";
import getJSONfromPublic from "./getJSONfromPublic"; // Adjust the path as necessary

import Sidebar from "./components/Sidebar";
import CardSlider from "./constants/cardSlider";
import PreviewGuide from "./PreviewGuide"; // Assuming PreviewGuide is in the components directory
import { useNavigate } from "react-router-dom"; // Import useNavigate

import useAppLocation from "./useAppLocation";

function HomeLayout() {
  const navigate = useNavigate(); // Hook to navigate
  // console.log("useContext(AuthContext)", useContext(AuthContext));
  const { user, userEmail, planId, isPaid, isAuthChecking, idToken } =
    useContext(AuthContext);
  const { shouldRenderSidebar } = useAppLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State to toggle the sidebar
  const [selectedGuide, setSelectedGuide] = useState(null);
  const [guideData, setGuideData] = useState(null);
  const [isExploreTabActive, setIsExploreTabActive] = useState(false); // New state to track if the Explore tab is active

  useEffect(() => {
    if (selectedGuide) {
      const { uid, recordingSessionId } = selectedGuide;
      // Navigate without replacing the current history entry
      navigate(`/video?id=${recordingSessionId}&source=server&uid=${uid}`);
    }
  }, [selectedGuide]);

  // Handler to update the Explore tab state
  const handleTabChange = (tabIndex) => {
    setIsExploreTabActive(tabIndex === 1); // Adjust the index based on your actual tab order
  };

  // console.log("selectedGuide", selectedGuide);
  return (
    <>
      <div className="grid grid-cols-1 md:flex md:grid-cols-none h-full w-full">
        {/* Render the sidebar only if it should be rendered and isSidebarOpen is true */}
        {
          <Sidebar
            onGuideSelect={setSelectedGuide}
            onTabChange={handleTabChange}
          />
        }

        <div className="flex flex-col m-2 p-2 w-full ">
          {guideData && isExploreTabActive && (
            <PreviewGuide guideData={guideData} />
          )}
          {/* Rest of the component's content */}

          {(!guideData || !isExploreTabActive) && (
            <>
              <section className="bg-gradient-to-b from-blue-50 to-gray-100 py-12">
                <div className="container mx-auto space-y-12 px-6 md:px-12">
                  {/* Hero Section */}
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
                      Learning - Made Easy
                    </h2>
                    <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                      <Hero />
                    </div>
                  </div>

                  {/* Featured Guide Section */}
                  <div className="text-center">
                    <h2 className="text-3xl font-extrabold text-gray-800 mb-4">
                      Creating Videos - Made Easy
                    </h2>
                    <div className="bg-white rounded-lg shadow-lg p-8 hover:shadow-xl transition-shadow duration-300">
                      <FeaturedGuide />
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
        {/* <Hero /> */}
        {/* <Features />
          <Testimonials />
          <Partners /> */}
      </div>
    </>
  );
}

export default HomeLayout;
